<template>
    <r-e-dialog title="水电用量修改" :visible.sync="dialogVisible" width="710px" top="10vh" show-footer
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel" append-to-body>
        <el-form ref="formPublish" label-width="135px" size="small" :model="formPublish" :rules="rules">
            <el-form-item label="上次水表读数">
                <el-input v-model="lastWater" placeholder="请输入内容" :disabled="true"/>
            </el-form-item>
            <el-form-item label="本次水表读数">
                <el-input v-model="currentWater" placeholder="请输入内容" :disabled="true"/>
            </el-form-item>
            <el-form-item label="本次水表实际读数" prop="water">
                <el-input v-model="formPublish.water" placeholder="请输入记录实际水表数"/>
            </el-form-item>
            <el-form-item label="上次电表读数">
                <el-input v-model="lastElect" placeholder="请输入内容" :disabled="true"/>
            </el-form-item>
            <el-form-item label="本次电表读数">
                <el-input v-model="currentElect" placeholder="请输入内容" :disabled="true"/>
            </el-form-item>
            <el-form-item label="本次电表实际读数" prop="elect">
                <el-input v-model="formPublish.elect" placeholder="请输入内容"/>
            </el-form-item>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {isNumeric, numberFormat} from "@custom";
import {MessageSuccess} from "@custom/message";
import {dailyUsageUpdate} from "@/api/tenant-management";

export default {
    name: "layer-daily-usage-update",
    data() {
        let validateNumber = (rule, value, callback) => {
            if (!isNumeric(value)) {
                callback(new Error('请输入数字!'));
            } else {
                callback();
            }
        };
        return {
            dialogVisible: false,
            formPublish: {
                uuid: null,
                elect: null,
                water: null,
            },
            rules: {
                water: [
                    {required: true, message: '请输入记录实际水用量', trigger: 'blur'},
                    {validator: validateNumber, trigger: 'blur'}
                ],
                elect: [
                    {required: true, message: '请输入记录实际电用量', trigger: 'blur'},
                    {validator: validateNumber, trigger: 'blur'}
                ],
            },
            loadingOptions: {
                lock: true,
                text: 'Loading...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            },
            lastWater: 0,
            currentWater: 0,
            lastElect: 0,
            currentElect: 0,
        }
    },
    methods: {
        async openDialog(data) {
            let that = this;
            const {uuid, currentWater, lastWater, currentElect, lastElect} = data;
            that.formPublish.uuid = uuid;
            that.lastWater = lastWater;
            that.currentWater = currentWater;
            that.lastElect = lastElect;
            that.currentElect = currentElect;
            that.dialogVisible = true;
        },
        clickSubmit() {
            let that = this;
            this.$refs['formPublish'].validate(valid => {
                if (valid) {
                    let {uuid, water, elect} = that.formPublish;
                    water = parseFloat(water);
                    elect = parseFloat(elect);
                    const data = {uuid, water, elect};
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    dailyUsageUpdate(data).then(res => {
                        MessageSuccess("编辑成功");
                        that.clickCancel();
                    }).finally(() => loading.close());
                }
            });
        },
        clickCancel() {
            this.$emit('handleSearch', false);
            this.formPublish = {
                uuid: null,
                elect: null,
                water: null,
            }
            this.dialogVisible = false;
        },
    },
}
</script>

<style scoped>

</style>